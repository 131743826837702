import { ref, computed } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import moment from 'moment';
import educationStoreModule from '../educationStoreModule';

export default function useEducationnListModal(props) {
  const STORE_MODULE_NAME = "education";

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, educationStoreModule);
  }

  const toastification = toast();

  const doctorItem = computed(() => props.doctor);

  const blankItem = {
    doctor: '',
    start_time: null,
    end_time: null,
    title: '',
    content: '',
  };
  const refModal = ref(null);
  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItemLocal = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };
  const data = ref([]);

  const resetModal = () => {
    blankItem.doctor = props.doctor._id;
    data.value = [];
    resetItemLocal();
  };

  const fetchData = () => {
    store.dispatch('education/fetchEducations', { doctor: doctorItem.value._id, start: 0, length: 1000 }).then(response => {
      data.value = response.data.data;
    }).catch(error => {
      toastification.showToastError(error);
    });
  };

  const onOpen = () => {
    resetModal();
    fetchData();
  };

  const onEditItem = val => {
    if (val) {
      item.value = val;
    } else {
      item.value = blankItem;
    }
  };

  const onDelete = val => {
    store.dispatch('education/deleteEducation', val._id).then(() => {
      toastification.showToastDeleteSuccess();
      fetchData();
    }).catch(error => {
      toastification.showToastError(error);
    });
  };

  // UI
  const resolveTimeDuration = val => {
    const startTime = moment(val.start_time);
    const endTime = moment(val.end_time);
    if (startTime.isValid() && endTime.isValid()) {
      return `${startTime.format('MM/YYYY')} - ${endTime.format("MM/YYYY")}`;
    } if (startTime.isValid()) {
      return `Từ ${startTime.format('MM/YYYY')}`;
    } if (endTime.isValid()) {
      return `Đến ${endTime.format('MM/YYYY')}`;
    }
    return 'Không xác định';
  };

  return {
    refModal,
    doctorItem,
    data,
    fetchData,
    onOpen,
    item,
    onEditItem,
    onDelete,
    resetItemLocal,
    resetModal,
    resolveTimeDuration,

  };
}
