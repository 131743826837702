import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchExps(ctx, data) {
      return useJwt.getExps(data).then(response => response);
    },
    createExp(ctx, data) {
      return useJwt.createExp(data).then(response => response);
    },
    updateExp(ctx, data) {
      return useJwt.updateExp(data).then(response => response);
    },
    deleteExp(ctx, id) {
      return useJwt.deleteExp(id).then(response => response);
    },

  },
};
