/* eslint-disable camelcase */
import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from "@/utils/toast";
import { getUserData, isMedicalFacility } from '@/auth/utils';
import doctorStoreModule from '../doctorStoreModule';

export default function useDoctorModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'doctor';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, doctorStoreModule);
  }

  // Use toast
  const toastification = toast();
  const refModal = ref(null);
  const isLoading = ref(false);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const fetchDoctorDetail = () => {
    if (props.item._id) {
      isLoading.value = true;
      store.dispatch('doctor/getDoctorDetail', props.item._id).then(response => {
        itemLocal.value = response.data;
        isLoading.value = false;
      }).catch(() => {
        toastification.showToastErrorWithMessage('Có lỗi trong quá trình lấy thông tin bác sĩ');
      });
    }
  };

  const onOpenModal = () => {
    fetchDoctorDetail();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const {
      avatar, full_name, phone, email, gender, workplace, qualification, specialist, sub_specialist, province, medical_facility, experience_years, description, is_family_doctor, is_personal_doctor, password, status, order,
    } = itemLocal.value;

    const data = {
      avatar,
      full_name,
      phone,
      email,
      workplace,
      qualification,
      experience_years,
      description,
      is_family_doctor,
      is_personal_doctor,
      password,
      order,
    };

    if (gender) {
      data.gender = gender.value;
    } else {
      data.gender = "other";
    }
    if (specialist) {
      data.specialist = specialist._id;
    } else {
      data.specialist = null;
    }
    if (sub_specialist) {
      data.sub_specialist = sub_specialist._id;
    } else {
      data.sub_specialist = null;
    }
    if (province) {
      data.province = province._id;
    } else {
      data.province = null;
    }
    if (isMedicalFacility()) {
      const userData = getUserData();
      data.medical_facility = userData.medical_facility._id;
    } else if (medical_facility) {
      data.medical_facility = medical_facility._id;
    } else {
      data.medical_facility = null;
    }

    if (status) {
      data.status = status.value;
    } else {
      data.status = null;
    }

    if (itemLocal.value._id) {
      delete data.password;
      data._id = itemLocal.value._id;
      store
        .dispatch('doctor/updateDoctor', data)
        .then(() => { })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
        });
    } else {
      store
        .dispatch('doctor/createDoctor', data)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
        });
    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isLoading,
    resetItemLocal,
    resetModal,
    onSubmit,
    onOpenModal,
  };
}
