import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRatings(ctx, data) {
      return useJwt.getRatings(data).then(response => response);
    },
    createRating(ctx, data) {
      return useJwt.createRating(data).then(response => response);
    },
    updateRating(ctx, data) {
      return useJwt.updateRating(data).then(response => response);
    },
    deleteRating(ctx, id) {
      return useJwt.deleteRating(id).then(response => response);
    },
  },
};
