import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchEducations(ctx, data) {
      return useJwt.getEducations(data).then(response => response);
    },
    createEducation(ctx, data) {
      return useJwt.createEducation(data).then(response => response);
    },
    updateEducation(ctx, data) {
      return useJwt.updateEducation(data).then(response => response);
    },
    deleteEducation(ctx, id) {
      return useJwt.deleteEducation(id).then(response => response);
    },
  },
};
