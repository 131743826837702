<template>
  <div class="doctor-list-container">

    <b-card no-body>
      <b-card-header>
        <b-card-title>Danh sách bác sĩ</b-card-title>
        <crud-buttons
          name="bác sĩ"
          modal="modal-doctor"
          :selected-rows="selectedRows"
          :show-import="getUserRole() && getUserRole().name === 'medical_facility'"
          :show-export="false"
          :hide-delete="true"
          :show-add="$can('create', 'Doctor')"
          @on-add="resetItem"
          @on-import-file="importData"
          @download-template-file="downloadImportTemplate"
        />
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            v-if="getUserRole().name !== 'medical_facility'"
            :md="resolveFilterLayout()"
          >
            <select-facility
              v-model="medicalFacility"
              :label="null"
            />
          </b-col>
          <b-col :md="resolveFilterLayout()">
            <select-department v-model="specialist" />
          </b-col>
          <b-col :md="resolveFilterLayout()">
            <select-user-status v-model="status" />
          </b-col>
          <b-col :md="resolveFilterLayout()">
            <b-form-input
              v-model="searchTerm"
              placeholder="Tìm kiếm ..."
              debounce="1000"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"

              :pagination-options="{
                enabled: true,
                perPage: serverParams.maxResultCount,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-apartment-detail
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>
                <span v-else-if="props.column.field === 'doctor'">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        rounded
                        :src="`https://api.vntelehealth.com:8443/media/view?token=${encodeURIComponent(props.row.avatar)}-100.webp`"
                        size="48"
                      />
                    </template>
                    <b-link
                      class="font-weight-bold d-block text-nowrap"
                    >
                      {{ props.row.full_name }}
                    </b-link>
                    <small class="text-muted">{{ props.row.phone }}</small>
                    <small class="text-muted"><br>{{ props.row.email }}</small>
                  </b-media>
                </span>

                <span v-else-if="props.column.field === 'status'">
                  <b-badge
                    pill
                    :variant="resolveStatusVariant(props.row.status).variant"
                    class="font-small-1"
                  >
                    {{ resolveStatusVariant(props.row.status).title }}
                  </b-badge>
                </span>
                <span v-else-if="props.column.field === 'is_personal_doctor'">
                  <b-badge
                    pill
                    :variant="resolveFamilyDoctorVariant(props.row.is_personal_doctor).variant"
                    class="font-small-1"
                  >
                    {{ resolveFamilyDoctorVariant(props.row.is_personal_doctor).title }}
                  </b-badge>

                </span>
                <span v-else-if="props.column.field === 'rating'">
                  <span v-if="props.row.rating_count && props.row.rating">
                    {{ props.row.rating }} <feather-icon
                      icon="StarIcon"
                    /> <b-link
                      v-b-modal.modal-rating-list
                      @click="onEditItem(props.row)"
                    >({{ props.row.rating_count }} đánh giá)</b-link>
                  </span>
                  <span v-else>Chưa có đánh giá</span>

                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    name="bác sĩ"
                    modal="modal-doctor"
                    :hide-delete="true"
                    :hide-edit="!$can('update','Doctor')"
                    @on-edit="onEditItem(props.row)"
                  >
                    <template
                      slot="other-buttons"
                    >
                      <b-button
                        v-b-tooltip.hover.v-primary
                        v-b-modal.modal-education-list
                        variant="primary"
                        class="btn-icon ml-20"
                        title="Học vấn"
                        size="sm"
                        @click="onEditItem(props.row)"
                      >
                        <feather-icon icon="BookOpenIcon" />
                      </b-button>
                      <b-button
                        v-b-tooltip.hover.v-warning
                        v-b-modal.modal-exp-list
                        size="sm"
                        variant="warning"
                        class="btn-icon ml-50"
                        title="Kinh nghiệm"
                        @click="onEditItem(props.row)"
                      >
                        <feather-icon icon="BriefcaseIcon" />
                      </b-button>
                      <b-button
                        v-if="$can('update', 'Doctor')"
                        v-b-tooltip.hover.v-danger
                        v-b-modal.modal-set-password
                        size="sm"
                        variant="danger"
                        class="btn-icon ml-50"
                        title="Đổi mật khẩu"
                        @click="onEditItem(props.row)"
                      >
                        <feather-icon icon="KeyIcon" />
                      </b-button>

                    </template>
                  </table-actions>
                </span>
                <span v-else-if="props.column.field === 'in_meeting_status'">
                  <b-form-checkbox
                    :checked="props.row.in_meeting_status === 'online' ? true : false"
                    class="custom-control-primary"
                    name="check-button"
                    switch
                    @input="checkSocket(props.row, props.row.originalIndex)"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="WifiIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="WifiOffIcon" />
                    </span>
                  </b-form-checkbox>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị tối đa </span>
                    <b-form-select
                      v-model="serverParams.maxResultCount"
                      :options="['10', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      trên tổng số {{ props.total }} kết quả
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.maxResultCount"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  Không có bản ghi nào!
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <doctor-modal
      :item="item"
      @refetch-data="fetchData"
    />
    <set-password-modal :doctor="item" />
    <exp-list-modal :doctor="item" />
    <education-list-modal :doctor="item" />
    <rating-list-modal :doctor="item" />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BAvatar,
  BMedia,
  BBadge,
  BButton,
  VBModal,
  VBTooltip,
  BFormCheckbox,
} from "bootstrap-vue";

import { VueGoodTable } from "vue-good-table";
import TableActions from "@/views/components/TableActions.vue";
import CrudButtons from "@/views/components/CrudButtons.vue";
// eslint-disable-next-line import/no-cycle
import SelectUserStatus from '@/views/components/SelectUserStatus.vue';
import SelectDepartment from '@/views/components/SelectDepartment.vue';
import SetPasswordModal from '@/views/components/set-password/SetPasswordModal.vue';
import { getUserRole } from '@/auth/utils';
import SelectFacility from '@/views/components/SelectFacility.vue';
import useDoctorList from "./useDoctorList";
import DoctorModal from '../modal/DoctorModal.vue';
import ExpListModal from '../exp/list/ExpListModal.vue';
import EducationListModal from '../education/list/EducationListModal.vue';
import RatingListModal from "../rating/list/RatingListModal.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BLink,
    BPagination,
    BAvatar,
    BFormSelect,
    BSpinner,
    BMedia,
    BBadge,
    BButton,
    BFormCheckbox,
    TableActions,
    CrudButtons,
    DoctorModal,
    VueGoodTable,
    SelectUserStatus,
    SelectDepartment,
    SetPasswordModal,
    ExpListModal,
    EducationListModal,
    SelectFacility,
    RatingListModal,
  },
  directives: {
    'v-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  sockets: {
    connect() {
      // Fired when the socket connects.
      // this.isConnected = true;

    },

    disconnect() {
      // this.isConnected = false;

    },

    // Fired when the server sends something on the "messageChannel" channel.
    messageChannel(data) {
      // this.socketMessage = data;

    },
  },
  setup() {
    const {
      analytics,
      item,
      columns,
      rows,
      specialist,
      status,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      medicalFacility,
      resolveFilterLayout,
      fetchData,
      importData,
      onEditItem,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,

      resolveStatusVariant,
      resolveFamilyDoctorVariant,
      downloadImportTemplate,
    } = useDoctorList();

    return {
      analytics,
      item,
      columns,
      rows,
      specialist,
      status,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      medicalFacility,
      resolveFilterLayout,
      importData,
      fetchData,

      onEditItem,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,

      resolveStatusVariant,
      resolveFamilyDoctorVariant,
      downloadImportTemplate,
      getUserRole,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    checkSocket(doctor, index) {
      this.$socket.emit('set_online_status', {
        status: doctor.in_meeting_status !== 'online',
        expected_checkout_time: 1667992666000,
      });
      this.rows[index].in_meeting_status = doctor.in_meeting_status !== 'online' ? 'online' : 'offline';
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
.doctor-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 100px !important;

            &:first-child {
              min-width: 50px !important;
            }
            &:nth-child(2) {
               min-width: 80px !important;
            }
            &:nth-child(3) {
               min-width: 80px !important;
            }
            &:nth-child(4) {
               min-width: 180px !important;
            }
            &:nth-child(5) {
               min-width: 180px !important;
            }
            &:nth-child(6) {
               min-width: 140px !important;
               width: 140px !important;
            }
            &:nth-child(7) {
               min-width: 100px !important;
               width: 100px !important;
            }
          }
        }
      }
    }
}

</style>
