/* eslint-disable camelcase */
import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from "@/utils/toast";
import moment from 'moment';
import educationStoreModule from '../educationStoreModule';

export default function useEducationModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'education';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, educationStoreModule);
  }

  // Use toast
  const toastification = toast();
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    itemLocal.value.start_time = moment(itemLocal.value.start_time).isValid() ? moment(itemLocal.value.start_time).format('MM-YYYY') : '';
    itemLocal.value.end_time = moment(itemLocal.value.end_time).isValid() ? moment(itemLocal.value.end_time).format('MM-YYYY') : '';
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const {
      start_time, end_time, title, content, doctor,
    } = itemLocal.value;

    const data = {
      title,
      content,
      doctor,
    };

    const startTime = moment(start_time, 'MM-YYYY');
    const endTime = moment(end_time, 'MM-YYYY');

    if (startTime.isValid()) {
      data.start_time = startTime.format();
    }
    if (endTime.isValid()) {
      data.end_time = endTime.format();
    }

    if (itemLocal.value._id) {
      data._id = itemLocal.value._id;
      store
        .dispatch('education/updateEducation', data)
        .then(() => { })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
        });
    } else {
      store
        .dispatch('education/createEducation', data)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
        });
    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    onSubmit,
  };
}
