import { ref, computed } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import moment from 'moment';
import ratingStoreModule from '../ratingStoreModule';

export default function useRatingnListModal(props) {
  const STORE_MODULE_NAME = "rating";

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, ratingStoreModule);
  }

  const toastification = toast();

  const doctorItem = computed(() => props.doctor);

  const blankItem = {
    doctor: '',
    start_time: null,
    end_time: null,
    title: '',
    content: '',
  };
  const refModal = ref(null);
  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItemLocal = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };
  const data = ref([]);

  const resetModal = () => {
    blankItem.doctor = props.doctor._id;
    data.value = [];
    resetItemLocal();
  };

  const fetchData = () => {
    store.dispatch('rating/fetchRatings', { doctorId: doctorItem.value._id, skipCount: 0, maxResultCount: 1000 }).then(response => {
      data.value = response.data.items;
    }).catch(error => {
      toastification.showToastError(error);
    });
  };

  const onOpen = () => {
    resetModal();
    fetchData();
  };

  const onEditItem = val => {
    if (val) {
      item.value = val;
    } else {
      item.value = blankItem;
    }
  };

  const onDelete = val => {
    store.dispatch('rating/deleteRating', val._id).then(() => {
      toastification.showToastDeleteSuccess();
      fetchData();
    }).catch(error => {
      toastification.showToastError(error);
    });
  };

  // UI
  const resolveTimeDuration = val => moment(val.created_at).format("DD/MM/YYYY HH:mm");

  return {
    refModal,
    doctorItem,
    data,
    fetchData,
    onOpen,
    item,
    onEditItem,
    onDelete,
    resetItemLocal,
    resetModal,
    resolveTimeDuration,

  };
}
