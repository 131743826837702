import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { getUserRole } from '@/auth/utils';
import doctorStoreModule from '../doctorStoreModule';

export default function useDoctorList() {
  const APARTMENT_STORE_MODULE_NAME = "doctor";

  // Register module
  if (!store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
    store.registerModule(APARTMENT_STORE_MODULE_NAME, doctorStoreModule);
  }

  // // UnRegister on leave
  // onUnmounted(() => {
  //   if (store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
  //     store.unregisterModule(APARTMENT_STORE_MODULE_NAME);
  //   }
  // });
  // Use toast
  const toastification = toast();

  const userData = ref(JSON.parse(localStorage.getItem('userData')));

  const blankItem = {
    avatar: '',
    full_name: '',
    phone: '',
    email: '',
    gender: null,
    order: 1,
    workplace: '',
    qualification: '',

    specialist: null,
    sub_specialist: null,
    medical_facility: null,
    province: null,
    experience_years: 1,
    description: '',

    status: null,
    is_family_doctor: false,
    is_personal_doctor: false,
    password: '',
    confirmPassword: '',
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  // Table Handlers
  const columns = [
    {
      label: 'Thao tác',
      field: 'action',
      width: '100px',
      tdClass: 'text-center',
      sortable: false,
      data: '_id',
      searchable: true,
      orderable: true,
    },
    {
      label: 'Bác sĩ',
      field: 'doctor',
      data: 'full_name',
      searchable: true,
      sortable: false,
    },
    {
      label: 'Cơ sở khám',
      field: 'medical_facility.name',
      data: 'medical_facility.name',
      searchable: true,
      orderable: false,
      hidden: userData.value.role.name === 'medical_facility',
      sortable: false,
    },
    {
      label: 'Khoa',
      field: 'specialist.name',
      data: 'specialist.name',
      searchable: true,
      orderable: false,
      sortable: false,
    },
    {
      label: 'Đánh giá',
      field: 'rating',
      sortable: false,
    },
    {
      label: 'Trạng thái',
      field: 'status',
      tdClass: 'text-center',
      thClass: 'text-center',
      width: '110px',
      type: 'boolean',
      data: 'status',
      searchable: true,
      orderable: true,
      sortable: false,
    },

  ];
  const analytics = ref();
  const rows = ref([]);
  // filter
  const status = ref(null);
  const specialist = ref(null);
  const medicalFacility = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    skipCount: 0,
    maxResultCount: 10,
    filter: '',
    specialist_id: null,
    status: null,
  });

  //   API Call

  const fetchDoctors = () => {
    store
      .dispatch('doctor/fetchDoctors', serverParams.value)
      .then(response => {
        const { items, totalCount } = response.data;
        totalRecords.value = totalCount;
        rows.value = items;
        isLoading.value = false;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };
  const fetchData = () => {
    isLoading.value = true;
    fetchDoctors();
  };
  const importData = file => {
    const formData = new FormData();
    formData.append('file', file);
    store
      .dispatch('doctor/importDoctors', formData)
      .then(() => {
        fetchData();
        toastification.showToastCreateSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const downloadImportTemplate = () => {
    store
      .dispatch('doctor/downloadImportTemplate', serverParams.value)
      .then(response => {
        // window.location.href = "https://resident.sgp1.digitaloceanspaces.com/dev-resident/import/Ba%CC%89ng%20nha%CC%A3%CC%82p%20lie%CC%A3%CC%82u%20ca%CC%86n%20ho%CC%A3%CC%82.xlsx";
        if (response.data && response.data.length > 0) {
          window.location.href = response.data;
        }
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const onEditItem = val => {
    item.value = val;
  };
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ skipCount: (params.currentPage - 1) * serverParams.value.maxResultCount });
  };

  const onPerPageChange = params => {
    updateParams({ maxResultCount: params.currentPerPage });
  };

  const onSortChange = params => {
    const column = columns.map(_obj => _obj.field).indexOf(params[0].field);

    updateParams({
      order: [{
        dir: params[0].type,
        column,
      }],
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ filter: val });
  });
  watch(status, val => {
    updateParams({ status: val ? val.value : null });
  });
  watch(specialist, val => {
    updateParams({ specialist_id: val ? val._id : null });
  });
  watch(medicalFacility, val => {
    updateParams({ medical_facility_id: val ? val._id : null });
  });

  // UI
  const resolveStatusVariant = val => {
    if (val === 'active') return { variant: 'primary', title: "Kích hoạt", icon: 'SmileIcon' };
    if (val === 'inactive') return { variant: 'warning', title: "Chưa kích hoạt", icon: 'MehIcon' };
    return { variant: 'danger', title: "Khóa", icon: 'FrownIcon' };
  };

  const resolveFamilyDoctorVariant = val => {
    if (val === true) return { variant: 'primary', title: "Có", icon: 'CheckIcon' };
    return { variant: 'danger', title: "Không", icon: 'MinusIcon' };
  };

  const getAvatarURL = avatar => {
    const arr = avatar.split('-');
    arr.splice(-1);
    const result = `https://api.vntelehealth.com:8443/media/view?token=${encodeURIComponent(arr.join('-'))}.jpeg`;

    return result;
  };

  const resolveFilterLayout = () => {
    const role = getUserRole();
    if (role.name === 'medical_facility') {
      return 4;
    }
    return 3;
  };

  return {
    analytics,
    item,
    columns,
    rows,
    specialist,
    status,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    medicalFacility,
    getAvatarURL,
    fetchData,
    importData,
    onEditItem,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,

    resolveStatusVariant,
    resolveFamilyDoctorVariant,
    downloadImportTemplate,
    resolveFilterLayout,
  };
}
